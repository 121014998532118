import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Cookies from "universal-cookie";

type Props = {
  price: number;
  discounted_price?: number;
  has_discount: boolean;
};

const PriceItem = ({ price, discounted_price, has_discount }: Props) => {
  const cookies = new Cookies();
  const { i18n } = useTranslation();
  let currency =
    i18n.language === "ar"
      ? cookies.get("currency_ar") || cookies.get("currency_en") || "KWT"
      : cookies.get("currency_en") || cookies.get("currency_ar") || "KWT";

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "2rem",
      }}
    >
      <Typography
        sx={{
          color: has_discount ? "white" : "#3ab5ff",
          position: "relative",
          fontSize: "1.5rem",
          fontWeight: "bold",
          border: has_discount ? "1px dashed #FFAD0A" : "none",
          borderRadius: "8px",
          padding: ".25rem 0.5rem",
          whiteSpace: "nowrap",
          "& span": {
            fontSize: ".875rem",
            margin: "0 0.25rem 0 0.25rem",
          },
          "&::after": {
            display: has_discount ? "block" : "none",
            content: '""',
            position: "absolute",
            width: "73%",
            height: "2.5px",
            backgroundColor: "#e8a01c",
            bottom: "55%",
            transform: "translateY(220%) translateX(-6px) rotate(15deg)",
            left: "13px",
            borderRadius: "2px",
          },
        }}
      >
        {`${price}`} <span>{currency}</span>
      </Typography>
      {has_discount && (
        <Typography
          sx={{
            fontSize: "1.5rem",
            fontWeight: "bold",
            color: "#3ab5ff",
            whiteSpace: "nowrap",
            "& span": {
              fontSize: ".875rem",
              margin: "0 0.25rem 0",
            },
          }}
        >
          {`${discounted_price}`}
          <span>{currency}</span>
        </Typography>
      )}
    </Box>
  );
};

export default PriceItem;
