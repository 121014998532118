import { Box, Collapse, Stack, Button, TextField } from "@mui/material";
import { motion } from "framer-motion";
import React, { useEffect } from "react";
import { MdOutlineExpandMore } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { singleItemType } from "../../../Types/Types";
import { singleOrderActions } from "../../../store/singleOrder-slice";
import SectionHead from "../SectionHead/SectionHead";
import SmallOrderCard from "../SmallOrderCard/SmallOrderCard";
import { useTranslation } from "react-i18next";
import useDebounce from "../../../Hooks/useDebounce";
import ItemsContainer from "./ItemsContainer";

type Props = {
  title: string;
  items: singleItemType[];
  type: "expandable" | "non-expandable";
  color?: string;
  itemType: string;
  mediaType: string;
};

const SingleSectionContainer = ({
  title,
  items,
  type,
  color = "#fff",
  itemType,
  mediaType,
}: Props) => {
  const singleOrderState = useSelector((state: any) => state.singleOrder);
  const dispatch = useDispatch();
  const [mediaItems, setMediaItems] = React.useState<singleItemType[]>(items);
  const { t } = useTranslation();

  const handleFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    const filteredItems = items.filter((item) => {
      //wait for the user to type the whole word
      value = value?.toLowerCase()?.split("")?.join("");
      const title_ar = item.name_ar?.toLowerCase()?.split("").join("");
      const title_en = item.name_en?.toLowerCase()?.split("").join("");
      return title_ar.includes(value) || title_en.includes(value);
    });
    setMediaItems(filteredItems);
  };
  const [searchText, setSearchText] = React.useState("");
  const debouncedSearchValue = useDebounce(searchText, 500);
  useEffect(() => {
    if (debouncedSearchValue) {
      handleFilter({ target: { value: debouncedSearchValue } } as any);
    } else {
      setMediaItems(items);
    }
  }, [debouncedSearchValue]);

  return (
    <Stack
      spacing={3}
      component={motion.section}
      initial={{ scale: 0.5 }}
      animate={{ scale: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        duration: 0.3,
      }}
      sx={{
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <SectionHead title={title} color={color} />
        <Stack spacing={2} direction="row" alignItems="baseline">
          <TextField
            variant="standard"
            sx={{
              "& .MuiInput-root": {
                color: "#fff",
                "&::before": {
                  borderBottom: "1px solid #fff",
                },
              },
              "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                borderBottom: "1px solid #fff",
              },
              "& .MuiInput-underline:after": {
                borderBottom: "1px solid #fff",
              },
              "& .MuiInputLabel-root": {
                color: "#fff",
              },
            }}
            label={t("search")}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
          />
          {type === "expandable" && (
            <Button
              sx={{
                backgroundColor: color,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                width: "90px",
                "&:hover": {
                  backgroundColor: color,
                },
                "& svg": {
                  fontSize: "1rem",
                  transform:
                    singleOrderState.expandedItem === title
                      ? "rotate(180deg)"
                      : "rotate(0deg)",
                  // color: color,
                },
              }}
              onClick={() => {
                if (singleOrderState.expandedItem === title) {
                  dispatch(singleOrderActions.setExpandedItem(""));
                } else {
                  dispatch(singleOrderActions.setExpandedItem(title));
                }
              }}
            >
              {t("actions.more")}
              <MdOutlineExpandMore />
            </Button>
          )}
        </Stack>
      </Box>

      <Box
        component={type === "expandable" ? Collapse : "div"}
        in={
          type === "expandable"
            ? singleOrderState.expandedItem === title
              ? true
              : false
            : true
        }
      >
        <ItemsContainer
          items={mediaItems}
          itemType={itemType}
          mediaType={mediaType}
        />
      </Box>
    </Stack>
  );
};

export default React.memo(SingleSectionContainer);
