import { IconButton, styled } from "@mui/material";

export const StyledIcon = styled(IconButton)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "60px",
  height: "60px",
  borderRadius: "50%",
  boxShadow: "2px 5px 16px #00000069",
  cursor: "pointer",
  transition: "all 0.3s ease-in-out",
  position: "relative",

  "& svg": {
    fontSize: "2.5rem",
  },
  "@media (min-width: 1650px)": {
    width: "60px",
    height: "60px",
    "& svg": {
      fontSize: "1.5rem",
      width: "1.5rem",
      height: "1.5rem",
    },
  },
  "@media (max-width: 1440px)": {
    width: "60px",
    height: "60px",
    "& svg": {
      fontSize: "1.75rem",
      width: "1.75rem",
      height: "1.75rem",
    },
  },
  "@media (max-width: 1280px)": {
    width: "45px",
    height: "45px",
    "& svg": {
      fontSize: "1.25rem",
      width: "1.25rem",
      height: "1.25rem",
    },
  },
  "@media (max-width: 424.99px)":{
    width: "30px",
    height: "30px",
  }
});

const navControls = [
  {
    id: 1,
    iconClass: "btn-main",
    iconIcon: "FaDollarSign",
    type: "currency",
    slug_en: "#",
    slug_ar: "#",
    submenu: [
      {
        id: 1,
        slug_en: "#",
        slug_ar: "#",
        title: {
          ar: "دينار كويتي",
          en: "KWD ",
        },
        exchangeRate: "",
      },
      {
        id: 2,
        slug_en: "#",
        slug_ar: "#",
        title: {
          ar: "دولار أمريكي",
          en: "USD",
        },
        exchangeRate: "",
      },
      {
        id: 3,
        slug_en: "#",
        slug_ar: "#",
        title: {
          ar: "ريال سعودي",
          en: "SR",
        },
        exchangeRate: "",
      },
      {
        id: 4,
        slug_en: "#",
        slug_ar: "#",
        title: {
          ar: "درهم إماراتي",
          en: "AED",
        },
        exchangeRate: "",
      },
    ],
  },
  {
    id: 2,
    iconClass: "btn-colored",
    iconIcon: "FaUser",
    type: "profile",
    slug_en: "#",
    slug_ar: "#",
    submenu: [
      {
        id: 1,
        slug_en: "/profile",
        slug_ar: "/profile",
        title: {
          ar: "الملف الشخصي",
          en: "Profile",
        },
      },
      {
        id: 2,
        slug_en: "/cart",
        slug_ar: "/cart",
        title: {
          ar: "مشترياتي",
          en: "Orders",
        },
      },
      {
        id: 3,
        slug_en: "/logout",
        slug_ar: "/logout",
        title: {
          ar: "تسجيل الخروج",
          en: "LogOut",
        },
      },
    ],
  },
  {
    id: 3,
    iconClass: "btn-main",
    iconIcon: "FaHeart",
    type: "Favorites",
    slug_en: "/favorites",
    slug_ar: "/favorites",
  },
  {
    id: 4,
    iconClass: "btn-colored",
    iconIcon: "FaShoppingCart",
    type: "cart",
    slug_en: "#",
    slug_ar: "#",
  },
];
