import { Stack } from "@mui/material";
import { Price } from "../../../Types/Types";
import MediaHeaderMainInfo from "./MediaHeaderMainInfo";
import MediaHeaderSecondaryInfo from "./MediaHeaderSecondaryInfo";

type Props = {
  type: string;
  productId: number;
  is_favorite: boolean;
  name: string;
  name_ar: string;
  category: string;
  descAr: string;
  descEn: string;
  image?: string;
  rating: string;
  noPeopleRated: string | number;
  category_ar: string;
  is_available: boolean;
  tags: {
    name: string;
    name_ar: string;
    label_value: string;
    label_value_ar: string;
  }[];
  price: Price;
  thumb: string;
  media_tags: string;
};

const MediaHeader = ({
  type,
  productId,
  name,
  category,
  image,
  descAr,
  descEn,
  noPeopleRated,
  tags,
  rating,
  is_favorite,
  category_ar,
  name_ar,
  price,
  is_available,
  thumb,
  media_tags,
}: Props) => {
  return (
    <Stack spacing={6} component={"section"}>
      <MediaHeaderMainInfo
        name={name}
        name_ar={name_ar}
        category={media_tags}
        category_en={category}
        category_ar={category_ar}
        id={productId?.toString()}
        type={type}
        is_favorite={is_favorite}
        price={price}
        is_available={is_available}
      />
      <MediaHeaderSecondaryInfo
        type={type}
        productId={productId}
        rating={rating}
        image={image}
        thumb={thumb}
        descAr={descAr}
        descEn={descEn}
        noPeopleRated={noPeopleRated}
        tags={tags}
      />
    </Stack>
  );
};

export default MediaHeader;
