import { createSlice } from "@reduxjs/toolkit";

interface initialStateType {
  payment_method: number;
  open: boolean;
  fatoorahUrl?: string;
  uPayment?: string;
}

const initialState: initialStateType = {
  payment_method: 4,
  open: false,
  fatoorahUrl: "",
  uPayment: ""
};

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    setPaymentMethod(state, action) {
      state.payment_method = action.payload;
    },
    handleOpen(state, action) {
      state.open = action.payload;
    },
  },
});

export const paymentActions = paymentSlice.actions;
export default paymentSlice;
