import React from "react";
import "./AuthTextField.scss";
import { useTranslation } from "react-i18next";

interface Props {
  type?: string;
  placeholder?: string;
  register?: any;
  error?: any;
  touched?: any;
  name?: string;
}

const AuthTextField = ({
  type,
  placeholder,
  register,
  error,
  touched,
  name,
}: Props) => {
  const { i18n } = useTranslation();
  return (
    <div className="auth-text-field-container">
      <input
        autoComplete="off"
        className="auth-text-field"
        placeholder={placeholder}
        type={type || "text"}
        {...register(name)}
      />
      {error && touched && (
        <p className={`${i18n.dir()} error`}>{error.message}</p>
      )}
    </div>
  );
};

export default React.memo(AuthTextField);
