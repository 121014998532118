import { Box } from "@mui/material";
import React from "react";
import { singleItemType } from "../../../Types/Types";
import SmallOrderCard from "../SmallOrderCard/SmallOrderCard";

type Props = {};

const ItemsContainer = ({ items, itemType, mediaType }: any) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        gap: "24px",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      {items?.map((item: singleItemType) => {
        return (
          <SmallOrderCard
            key={item?.id}
            {...item}
            itemType={itemType}
            mediaType={mediaType}
          />
        );
      })}
    </Box>
  );
};
export default React.memo(ItemsContainer);
